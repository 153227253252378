<mat-toolbar class="mat-elevation-z6">
  <button mat-button [routerLink]="['/']">Home</button>
  <button mat-button [routerLink]="['/services']">Services</button>
  <button mat-button [routerLink]="['/experiences']">Experiences</button>
  <span class="example-fill-remaining-space"></span>
    <div class="mat-button-ripple mat-ripple" matripple=""></div>
    <div class="mat-button-focus-overlay"></div>
    <span class="mat-button-wrapper">
      <img _ngcontent-c1="" alt="angular" class="docs-angular-logo" src="assets/angular-white-transparent.svg" />
    </span>
</mat-toolbar>
<div class="docs-primary-header component-page-header" style="height:10px"></div>
<router-outlet></router-outlet>