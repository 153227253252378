<div class="component">
  <div class='basic-container'>

    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>María Cárdenas</mat-card-title>
        <mat-card-subtitle>File Voodoo - Experiences</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image [src]="imagePath" alt="maria-cardenas">
      <mat-card-content>
        <p style="font-style:italic;">When we organize your files,<br />your business will run like magic.</p>
      </mat-card-content>
    </mat-card>

    <mat-card class="example-card">
      <mat-card-content>
        <table>
          <tr>
            <td>Extensive experience in administrative support, bookkeeping, calendar management,
              database management, office management, travel arrangements.</td>
          </tr>
          <tr>
            <td>Top-notch writing, editing and proofreading skills.</td>
          </tr>
          <tr>
            <td>Ability to implement office processes and create process manuals.</td>
          </tr>
          <tr>
            <td>Proficiency in Adobe Acrobat Pro; MS Excel, Outlook, PowerPoint and Word; 13 years
              of QuickBooks experience.</td>
          </tr>
          <tr>
            <td>High level of confidentiality in all financial and personnel matters.</td>
          </tr>
          <tr>
            <td>Excellent reception, customer service, organizational and event planning skills (including
              office moves).</td>
          </tr>
          <tr>
            <td>Experience in various industries: accounting, construction, engineering, finance, law,
              nonprofit, sales, state government and travel.</td>
          </tr>
          <tr>
            <td>Ability to multi-task, problem solve and maintain good working relationships with
              building management, clients/customers, co-workers and vendors.</td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>

    <mat-card class="example-card">
      <mat-card-content>
        <p>EDUCATION</p>
        <ol>
          <li>Ohio University<br />
            Athens, OH.<br />
            Bachelor of Science – Journalism, <br />
            <span style="font-style:italic;">Cum&nbsp;Laude</span>.<br />
            Major: Advertising.<br />
          <li>Writers Boot Camp and Think Tank<br />
            Chicago, IL.<br />
            Intensive Screenwriting Classes <br />
            and Workshops.</li>
        </ol>
        <p>ADDITIONAL</p>
        <ul>
          <li>
            Notary Public<br />
            (commission expires January 2024)</li>
          <li>
            Permanent Employee Registration Card<br />
            (expires May 2021)</li>
        </ul>
        <p>Go back to <a routerLink="/" routerLinkActive="active">Office Organization</a> home.</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="goToLinkedIn()">LINKEDIN</button>
        <button mat-button (click)="goToPDF()">PDF</button>
        <button mat-button (click)="emailSupport()">EMAIL</button>
      </mat-card-actions>
    </mat-card>

  </div>
</div>