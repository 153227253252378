<div class="component">
  <div class='basic-container'>

    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>María Cárdenas</mat-card-title>
        <mat-card-subtitle>File Voodoo - Services</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image [src]="imagePath" alt="maria-cardenas">
      <mat-card-content>
        <p style="font-style:italic;">When we organize your files,<br />your business will run like magic.</p>
      </mat-card-content>
    </mat-card>

    <mat-card class="example-card">
      <mat-card-content>
        <p>Over the years, I’ve dealt with a lot of filing issues, from building a paper system from scratch to
          scanning boxes of paper tax documents to digital copies and organizing them for other people to
          utilize easily.</p>
        <p>My main services are:</p>
        <ol>
          <li>Organizing paper files.</li>
          <li>Organizing digital files.</li>
          <li>Turning paper files into digital ones.</li>
        </ol>
        <p>I can do this onsite with the proper COVID-19 safety precautions in place. </p>
        <p>Or I can scan them directly to your cloud storage, VPN or a hard drive if you prefer to deliver them to my office.</p>
      </mat-card-content>
    </mat-card>

    <mat-card class="example-card">
      <mat-card-content>
        <p>In addition, I can also offer:</p>
        <ul>
          <li>Editing, proofreading and writing.</li>
          <li>Process manual creation.</li>
          <li>Data entry into your online financial software.</li>
          <li>Database clean-up and maintenance.</li>
        </ul>
        <p style="font-style:italic;font-weight:bold">"When we organize your files,<br />your business will run like magic."</p>
        <p>Let me show you an administrative trick or two.</p>
        <p>Check out my <a routerLink="/experiences" routerLinkActive="active">Experience</a> too.</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="goToLinkedIn()">LINKEDIN</button>
        <button mat-button (click)="goToPDF()">PDF</button>
        <button mat-button (click)="emailSupport()">EMAIL</button>
      </mat-card-actions>
    </mat-card>

  </div>
</div>