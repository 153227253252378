import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductListComponent } from './product-list/product-list.component';
import { ServicesListComponent } from './services-list/services-list.component';
import { ExperiencesListComponent } from './experiences-list/experiences-list.component';

const routes: Routes = [
  { path: '', component: ProductListComponent, pathMatch: 'full' },
  { path: 'services', component: ServicesListComponent, pathMatch: 'full' },
  { path: 'experiences', component: ExperiencesListComponent, pathMatch: 'full' },
  { path: '**', component: ProductListComponent, pathMatch: 'prefix' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
