import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  imagePath = "assets/maria.jpg";

  constructor() { };

  ngOnInit() {
  };

  goToLinkedIn() {
    const link = "https://www.linkedin.com/in/maria-v-cardenas-7a04b316/";
    window.open(link, '_blank');
  }
  
  emailSupport() {
    location.href = "mailto:maria.v.cardenas0706@gmail.com?subject='I need help'&body='write some message'";
  }

  goToPDF() {
    const link = "https://filevoodoo.com/filevoodoo.pdf";
    window.open(link, '_blank');
  }
};
