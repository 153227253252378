<div class="component">
  <div class='basic-container'>

    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>María Cárdenas</mat-card-title>
        <mat-card-subtitle>File Voodoo - Office Organization</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image [src]="imagePath" alt="maria-cardenas">
      <mat-card-content>
        <div class="slogan">When we organize your files,<br />your business will run like magic.</div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="goToLinkedIn()">LINKEDIN</button>
        <button mat-button (click)="goToPDF()">PDF</button>
        <button mat-button (click)="emailSupport()">EMAIL</button>
      </mat-card-actions>
    </mat-card>

    <mat-card class="example-card">
      <mat-card-content>
        <p>Hi! Thanks for stopping by.</p>
        <p>I bet you’re wondering if I’m really going to discuss filing.
          Well, I am because it’s important. Here we go.</p>
        <p>Filing is nuts and bolts. It’s basic and practical so it’s not often prioritized.
          But organized files are the foundation of any business.
          That becomes evident fast when you receive a letter from the
          IRS or construction union and the subject line is: AUDIT.</p>
        <p>
          I’ve worked in many offices, in many different industries. They all have one thing in common:
          disheveled files.</p>
        <p>You know what I’m talking about. The documents in hanging files are stuffed
          every which way.
          And you have to look through the unorganized contents of each one to find
          what you need.</p>
      </mat-card-content>
    </mat-card>

    <mat-card class="example-card">
      <mat-card-content>
        <p>Then you look at the digital files – and the real horror begins. Because they’re also in disarray
          (and sometimes worse):</p>
        <ul>
          <li>You either have too many subfolders or not enough.</li>
          <li>Nothing is dated or named consistently.</li>
          <li>Some documents were scanned to the wrong folder from day one, so no one can find
            them – or there are copies in multiple places.</li>
        </ul>
        <p>Now humor me and think about these questions as they relate to your company’s files:</p>
        <ol>
          <li>How much time do you waste looking for a document because no one can find it?</li>
          <li>Does your office manager’s eyes glaze over when you ask her if the filing’s up-to-date?</li>
          <li>Would you rather face a charging rhino instead of tackling the backlog of office filing?</li>
        </ol>
        <p>Check out my <a routerLink="/services" routerLinkActive="active">Services</a>, because I can help you.</p>
      </mat-card-content>
    </mat-card>

  </div>
</div>